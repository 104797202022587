@import '../../../assets/css/base';

.manage-devices {
  .sticky-devices-header {
    position: static;
    top: 72px;
    overflow-y: hidden;
    background: var(--brand_white);
    padding: 18px;
    margin-top: 0;

    &-filter {
      position: fixed;
      top: 210px;
      overflow-y: hidden;
      padding: 18px;
      background-color: transparent;
      .device-status-selector-menu {
        position: absolute;
        z-index: 1000;
      }
    }
  }

  .device-list-wrapper {
    padding: 10px 0px;
    overflow-y: hidden;
    margin-top: 30px;

    .filter-wrapper {
      background-color: transparent;
      padding: 22px 26px;
      top: 176px;
      z-index: 1000;
      width: 100%;

      .filter-column {
        background-color: var(--brand_white);
      }
      .filter-container {
        width: 100%;

        .search-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 730px;

          .search-icon {
            color: var(--custom_color_19);
            position: absolute;
            z-index: 9;
            margin: 12px 8px 12px 16px;
          }

          .search-input {
            display: flex;
            padding: 12px 16px 12px 40px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 0;
            outline: 1.5px solid var(--greyscale_88);
            background: var(--brand_white);
            color: var(--custom_color_19);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            width: 100%;
            height: 44px;

            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:hover {
              border: 0;
              outline: 1.5px solid var(--primary_40) !important;
              background-color: var(--brand_white) !important;
              color: var(--custom_color_19) !important;
            }
          }
          & .search-close {
            width: 16px;
            height: 16px;
            position: absolute;
            cursor: pointer;
            right: 16px;
          }
        }
      }
    }

    .filter-wrapper-height {
      height: calc(100vh - 100px);
    }

    .list-wrapper {
      margin-top: 0;

      .accordion-list {
        padding-top: 0;
      }
    }
  }
}

.discovered-devices {
  .status-indicator {
    margin-right: 0.375rem;
    height: 0.625rem !important;
    width: 0.625rem !important;

    &.status {
      &-online {
        color: var(--success_48);
      }

      &-claiming {
        color: var(--primary_48);
      }

      &-entered {
        color: var(--warning_64);
      }

      &-offline {
        color: var(--error_48);
      }

      &-ready-to-claim {
        color: var(--greyscale_40);
      }
    }
  }
  .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
    padding: 0px;
  }
  .css-mkl144 {
    padding: 0px;
  }
  .css-mkl144.Mui-checked {
    padding: 0px;
    color: var(--primary_40) !important;
  }
  .MuiFormGroup-root .MuiRadio-root {
    padding: 0px;
    &.Mui-checked {
      color: var(--primary_40);
    }
  }
  .table-action-dropdown {
    &:focus-visible {
      outline: unset;
    }

    .dropdown-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--greyscale_40);
      padding: 8px 16px;
      gap: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .active,
      &:active,
      &:focus,
      &:hover {
        background-color: var(--grayscale_96);
      }
      &.disabled {
        color: var(--greyscale_72);
      }
    }
    .location-area-dropdown-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }
    .dropdown-menu {
      box-shadow:
        0px 10px 15px -3px rgb(0 0 0 / 10%),
        0px 4px 6px -2px rgb(0 0 0 / 5%);
      border: 0;
      border-radius: 12px;
      width: 200px;
      padding: 8px 0px;
      margin-top: 8px;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
  .device-status-selector {
    &-toggle {
      &.dropdown-toggle {
        display: inline-flex;
        height: 32px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        background: var(--primary_40);
        color: var(--brand_white);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 142.857% */
        border: none;
        width: 157px;
        justify-content: center;
        &.disabled {
          background: var(--Greyscale-Greyscale-88, #dcdfe5);
          color: var(--Greyscale-Greyscale-32, #454f5f);
        }
        &.btn-outline-secondary {
          &.show {
            background-color: var(--primary_24) !important;
            color: var(--brand_white) !important;
            border: none;
          }
        }

        &::after {
          display: none !important;
        }

        &:hover {
          background-color: var(--primary_24) !important;
          color: var(--brand_white) !important;
          border: none;
        }
      }
    }

    &-menu {
      &.dropdown-menu {
        width: 200px;
        padding: 8px 0px;
        align-items: flex-start;
        margin-top: 8px;
        &.show {
          border-radius: 12px;
          background: var(--brand_white);
          box-shadow:
            0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
            0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);
        }

        & .dropdown-item {
          display: flex;
          padding: 8px 12px !important;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          color: var(--greyscale_40);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          &.active {
            color: var(--greyscale_40);
            background-color: var(--brand_white);
            font-weight: 700;
          }

          &:last-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          &.disabled {
            color: var(--greyscale_72);
          }
        }
      }
    }

    .device-status-selected {
      &-wrapper {
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .no-cameras {
    &-container {
      display: flex;
      height: 192px;
      padding: 44px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--grayscale_96);
    }
    &-icon-container {
      display: flex;
      width: 60px;
      height: 60px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(--grayscale_96);
    }
    &-notice {
      color: var(--grayscale_56);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .load-more {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: var(--primary_40);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
  .load-more-disabled {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: var(--primary_88);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: auto;
  }
  .error-container {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: var(--error_24);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-radius: 12px;
    background: var(--error_96);
    align-items: center;
    justify-content: space-between;
    .message-box {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;
    }
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: fixed;
  }
  .table thead,
  .table tbody,
  .table tfoot,
  .table tr,
  .table td,
  .table th {
    text-align: start;
    padding: 0px 0px 0px 16px;
    height: 48px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .table {
    thead {
      height: 40px;
      position: sticky;
      top: 0;
      z-index: 999;
      tr,
      th {
        height: 40px;
      }
    }
  }

  thead,
  .table > :not(:first-child) {
    border-top: 0;
  }

  .table thead td,
  .table thead th {
    color: var(--greyscale_56);
    height: 40px;
  }

  .table tbody td {
    border-style: none solid solid none;
    color: var(--greyscale_08);
    background-color: transparent;
  }
  .table tbody tr {
    background-color: var(--grayscale_96);
    height: 48px;
    &:hover {
      background-color: var(--greyscale_88);
    }
    cursor: auto;
    &.no-data-found {
      background-color: transparent;

      td {
        border-color: transparent;
        color: var(--brand_primary);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .table-scroll {
    overflow-y: auto;
    height: calc(100vh - 232px);
  }
  .table tbody td svg {
    width: 18px;
    height: 18px;
  }

  .table tr td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .table tr td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-right: 16px;
    overflow: visible;
  }
  .table tr th:last-child {
    padding-right: 16px;
    overflow: visible;
  }
  .table tr td:nth-child(7) {
    overflow: visible;
  }
  .table tr td:nth-child(8) {
    overflow: visible;
  }
  .table .header-arrow {
    margin-left: 8px;
    cursor: pointer;
    color: var(--greyscale_56);
    height: 24px;
    width: 24px;
  }
  .table .header-arrow svg path {
    stroke: var(--greyscale_56);
  }

  .table .columnheader-desc {
    color: var(--greyscale_56);
  }

  .table .columnheader-desc svg path {
    stroke: var(--greyscale_56);
  }

  .table .columnheader-asc {
    color: var(--greyscale_56);
  }

  .table .columnheader-asc svg path {
    stroke: var(--greyscale_56);
  }
  .table .set-location-area {
    overflow: hidden;
    color: var(--primary_64);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
  .table .location-area-name {
    overflow: hidden;
    color: var(--primary_40);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
  .table .option-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
  }
  .table .viewCheckbox input[type='checkbox'] {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1.5px solid var(--greyscale_80);
    background: var(--brand_white);
    appearance: none;
    cursor: pointer;
    position: relative;
  }
  .table .viewCheckbox input[type='checkbox']:disabled {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1.5px solid var(--greyscale-40);
    background: var(--greyscale_48);
    appearance: none;
    cursor: auto;
  }
  .table .viewCheckbox input[type='checkbox']:checked::after {
    position: absolute;
    width: 16px;
    height: 16px;
    color: var(--brand_white);
    content: '\2713';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    background: var(--primary_40);
    cursor: pointer;
  }
  .table input[type='text'] {
    display: flex;
    padding: 10px 8px 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    height: 32px;
    width: 90%;
  }
  .table input[type='text']:focus-visible {
    outline: unset;
  }

  table .deviceChecked {
    width: 40px;
    padding-right: 8px;
  }
  table .deviceName {
    width: 15%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px !important;
  }
  table .modelNumber {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .createdDate {
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .locationName {
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .areaName {
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .connectionStatus {
    width: 9%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .ipAddress {
    width: 9%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table .deviceOptions {
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selection-wrapper-location-area {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-left: -10px;
  margin-right: -10px;
  flex-shrink: 0;
  .dropdown-item.active {
    background: none !important;
  }
}

.area-selector {
  width: 100%;
  &-toggle {
    &.dropdown-toggle {
      display: flex;
      padding: 12px 16px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;
      width: 100%;
      border: 0;
      outline: 1.5px solid var(--hGreyscale_88) !important;
      background: var(--brand_white);
      color: var(--custom_color_19);
      font-family: 'Noto Sans';
      font-size: 14px !important;
      font-weight: 500;
      line-height: 20px !important;
      width: 205px;
      height: 44px;

      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:hover {
        border: 0;
        outline: 1.5px solid var(--primary_40) !important;
        background-color: var(--brand_white) !important;
        color: var(--custom_color_19) !important;
      }

      &.btn-outline-secondary {
        &.show {
          border: 0;
          outline: 1px solid var(--primary_40);
          background: var(--brand_white);
          color: var(--custom_color_19);
          border-radius: 10px;
        }
      }

      &::after {
        display: none !important;
      }
    }
  }

  &-menu {
    overflow-y: auto;
    max-height: 300px;

    &.dropdown-menu {
      width: 100%;
      padding: 8px 0px;
      background: var(--brand_white);
      &.show {
        box-shadow:
          0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
        border-radius: 12px;
      }
    }

    &-item {
      &.dropdown-item {
        width: 100%;
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        border: none;
        background: var(--brand_white);
        color: var(--brand_black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          background: var(--hGreyscale_88);
          color: var(--brand_black);
          font-weight: 700;
        }

        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
      &.dropdown-item:hover {
        background: var(--primary_01);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }

  .area-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }

    &-name {
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 120px;
      text-align: left;
    }
  }
}

.radio-container {
  display: flex;
  white-space: normal;
  max-width: 200px;
}

.device-status-selector {
  &-toggle {
    &.dropdown-toggle {
      display: inline-flex;
      height: 44px;
      padding: 8px 16px;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--greyscale_08);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border: none;
      &.btn-outline-secondary {
        &.show {
          background-color: var(--grayscale_96) !important;
          color: var(--greyscale_08) !important;
          border: none;
        }
      }

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: var(--grayscale_96) !important;
        color: var(--greyscale_08) !important;
        border: none;
      }
    }
  }

  &-menu {
    &.dropdown-menu {
      width: 200px;
      padding: 8px 0px;
      align-items: flex-start;
      margin-top: 8px;
      margin-left: -87px;
      &.show {
        border-radius: 12px;
        background: var(--brand_white);
        box-shadow:
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
          0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);
      }

      & .dropdown-item {
        display: flex;
        padding: 8px 12px !important;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        display: flex;
        &.active {
          color: var(--greyscale_08);
          background-color: var(--brand_white);
          font-weight: 700;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        & .checkbox {
          width: 16px;
          height: 16px;
          accent-color: var(--primary_40);
        }
      }
    }
  }

  .device-status-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }
  }
}

.filter-area-style {
  margin-left: 1px !important;
}

.custom-option-device-list {
  width: 233px !important;
}

.custom-option-device-menu {
  width: 230px !important;
}

.location-search-box {
  width: 90%;
  margin-left: 12px;
  padding: 8px 30px 8px 31px;
  border: 1px solid var(--hGreyscale_88);
  border-radius: 12px;
  box-sizing: border-box;
  font-size: toRem(16px);
  background-color: var(--brand_white);
}

.location-search-box:focus {
  border-color: var(--brand_primary);
  outline: 0;
}

.location-search-box::placeholder {
  color: var(--text_color_obj);
}

.search-icon-location {
  position: absolute;
  z-index: 9;
  margin: 12px 8px 12px 20px;
  height: 20px;
  width: 20px;
}

.location-label {
  vertical-align: middle;
  margin-left: 4px;
  font-weight: 100;
}

.location-radio {
  vertical-align: middle;
  filter: hue-rotate(167deg) brightness(1.4);
}
