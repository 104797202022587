@import '../../../../assets/css/base.scss';

.col {
  padding-left: 0;
  padding-right: 0;

  .license {
    &-block {
      .list-block-item {
        padding: 0px;
        margin: 0;
        background-color: transparent;
      }

      .card-body {
        margin: 0;
      }
    }

    &-features {
      list-style: none;
      border: 0;
      padding-left: 0;

      li {
        padding: 16px 4px;
        margin: 0 0 10px;
        border-radius: 12px;
        background-color: var(--grayscale_96);

        .container {
          .no-licenses {
            &-attached {
              display: flex;
              justify-content: space-around;
              text-align: center;
              font-size: toRem(14px);
              font-weight: 500;
              line-height: toRem(20px);
              text-transform: none;
            }

            &-available {
              font-size: toRem(12px);
              color: var(--greyscale_56);
              text-transform: none;
            }
          }
        }
      }

      .purchase-button-container {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
      }
    }

    &-action {
      color: var(--primary_40);
      text-align: right;
      cursor: pointer;
      font-weight: 500;
      font-size: toRem(16px);

      &:hover {
        text-decoration: underline;
      }
    }

    &-prop {
      color: var(--greyscale_56);
      font-size: toRem(12px);
      font-weight: 500;
      line-height: 1.15rem;

      &-value {
        display: inline-flex;
        color: var(--greyscale_08);
        font-size: toRem(16px);
        line-height: 1.15rem;
        align-items: center;

        .value-wrapper {
          margin-left: 6.5px;
        }
      }
    }
  }
}

.spacer {
  height: 10px;
  width: 100vw;
}

.license-prop-title {
  font-size: toRem(12px);
  font-weight: 500;
  line-height: toRem(16px);
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--hGreyscale_56);
  margin-bottom: 5px !important;
  padding-left: 15px;
}

.license-prop-value {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
  text-align: left;
  float: left;
  padding-left: 20px;
  text-transform: none;
}

.license-prop-value-eval-onabording {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
  text-align: left;
  float: left;
  padding-left: 9px;
  color: var(--greyscale_08);
  text-transform: none;
}

.license-prop-date-value {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
  text-align: left;
  color: var(--greyscale_08);
}

.license-warning-prop-date-value {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
  text-align: left;
  color: var(--error_48);
}

.license-block-row-style {
  margin-bottom: 12px !important;
}

.license-prop-calender-value {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
  text-align: left;
  float: left;
  padding-left: 22px;
}

.license-prop-icon {
  width: 34px !important;
  margin-left: -15px;
}

.license-expiring-soon-icon-style {
  margin-left: -15px;
  margin-right: 2px;
  margin-top: -2px;
}

.warning-icon-style {
  fill: var(--error_48);
  font-size: toRem(20px);
}

.error-icon-style {
  stroke: var(--error_48);
  font-size: toRem(20px);
}

.success-icon-style {
  stroke: var(--success_48);
  font-size: toRem(20px);
}

.expiring-soon-icon-style {
  stroke: var(--warning_48);
  font-size: toRem(20px);
}

.license-action-style {
  margin-top: 16px;
  margin-left: 4px;
  float: left;
  font-size: toRem(16px) !important;
}

.license-block-row-style {
  margin-bottom: 12px !important;
}

.attach-new-license-btn {
  float: right;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .license-prop-value {
    padding-left: 16px;
  }
  .license-prop-calender-value {
    padding-left: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .license-prop-value {
    padding-top: 6px;
  }
  .license-prop-calender-value {
    padding-top: 6px;
  }
}

@media screen and (max-width: 767px) {
  .license-action-style {
    margin-top: -20px;
  }
}

.attach-license-style {
  .modal-dialog .modal-content {
    padding: 32px 8px 12px 8px;
  }
  .modal-dialog.modal-md.modal-dialog-centered .modal-header {
    padding-left: 12px;
    padding-right: 19px;
  }
}

.attached-success-message {
  padding-left: 45px;
  padding-right: 32px;
  text-align: center;
}

.attached-license-mark-style {
  margin-top: 15px;
}

.go-back-btn-style {
  margin-top: 20px;
  padding-left: 36px;
  padding-right: 36px;
}
.display-f {
  display: flex;
}
.m-l-10 {
  margin-left: 10px;
}
.license-type-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  cursor: pointer;
}
.license-text-holder {
  padding: 18px;
  .m-t-10 {
    margin-top: 10px;
  }
}
.continue-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
}
.cancel-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
}
.license-type {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 24px;
        }
      }
    }
  }
}

input.license-type-checkbox {
  appearance: none; /* Remove default styles */
  width: 16px;
  height: 16px;
  border: 1px solid grey; /* Default grey border */
  border-radius: 50%;
  background: white;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s; /* Smooth transition */
}

/* Change border color to orange when checked */
input.license-type-checkbox:checked {
  border-color: var(--primary_40);
}

/* Add the small filled orange circle when checked */
input.license-type-checkbox:checked::before {
  content: '';
  width: 9px; /* Smaller inner circle */
  height: 9px;
  background: var(--primary_40); /* Inner filled orange */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.detach-license-modal {
  .site-modal-close {
    width: 30px;
    height: 30px;
  }
}
