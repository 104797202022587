@import '../../../assets/css/base.scss';

.export-incident {
  &-row-container {
    display: flex;
    justify-content: space-between;

    .export-incident-main-content {
      max-width: 510px;
      margin: 0;
      padding: 0;
    }

    .export-incident-title-column {
      padding-right: 0;
    }

    .export-incident-checkbox-wrapper {
      display: flex;
      justify-content: flex-start;

      &.hidden {
        display: none;
      }

      .MuiFormControlLabel-label {
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 400;
        line-height: toRem(20px);
      }

      .transcode-checkbox {
      }
    }
  }

  &-col-container {
    &.export-log-column {
      max-width: 510px;
      padding-left: 0;
    }
  }

  &-title {
    color: var(--brand_black);
    font-size: toRem(20px);
    font-weight: 600;
    line-height: toRem(28px);
  }

  &-description {
    color: var(--greyscale_40);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-notification {
    &-wrapper {
      display: flex;
      padding: 12px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--warning_96);
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &-icon {
      color: var(--warning_24);
      height: 20px;
      width: 20px;
    }

    &-content {
      margin-top: 3px;
    }

    &-title {
      color: var(--warning_24);
      font-size: toRem(16px);
      font-weight: 600;
      line-height: toRem(20px);
      text-align: left;
    }

    &-description {
      color: var(--warning_24);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(20px);
      text-align: left;
      margin-top: 2px;
    }
  }

  &-button-container {
    .col {
      margin: 0;
      padding: 0;
    }
  }

  &-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .export-incident-export-btn {
      padding: 8px 12px;
      color: var(--brand_white);
      font-size: toRem(14px);
      font-weight: 600;
      line-height: toRem(20px);
    }
  }

  &-export-log {
    &-wrapper {
      max-width: 510px;

      .export-log-detail {
        &-container {
          padding-left: 20px;
          padding-right: 5px;
        }

        &-wrapper {
          display: flex;
          padding: 22px 20px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 12px;
          background-color: var(--grayscale_96);
        }

        &-content {
          display: flex;
          justify-content: flex-start;
        }

        &-no-history-content {
          width: 100%;
        }

        &-no-logs {
          display: flex;
          width: 100%;
          padding: 44px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 12px;
          background: var(--grayscale_96);
        }

        &-no-history {
          color: var(--grayscale_56);
          font-size: toRem(16px);
          font-weight: 500;
          line-height: toRem(20px);
        }

        &-export-icon {
          color: var(--greyscale_08);
          height: 32px;
          width: 32px;
        }

        &-avatar {
          margin-right: 8px;
        }

        &-creator {
          text-align: left;

          &-name {
            color: var(--greyscale_08);
            font-size: toRem(16px);
            font-weight: 500;
            line-height: toRem(20px);
          }

          &-date {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
          }
        }
      }
    }

    &-title {
      color: var(--brand_black);
      font-size: toRem(20px);
      font-weight: 600;
      line-height: toRem(28px);
    }
  }

  .toast-wrapper-incident-export {
    max-width: 200px !important;
    position: absolute;
    background-color: var(--brand_white);
    top: 0;
    right: 0;
  }
}
