@import '../../../../assets/css/_utilities.scss';

.sub-header-analytics {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  margin-top: 18px;
  margin-bottom: 18px;
}

.toggle-icon-edit {
  margin-right: 10px !important ;
}

.range-field-label {
  color: var(--brand_black);
  font-weight: 900;
  font-size: toRem(16px);
  line-height: 16px;
}

.sitemodal-inner {
  margin-top: 10px;
}

.sound-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sound-checkbox {
  margin-left: auto;
  width: 20px;
  height: 20px;
  border: 1.5px solid #cccfd1;
}
.sound-label {
  font-size: 16px;
  color: var(--text_color_event);
  font-weight: 500;
}

.sound-label-icon {
  margin-right: 10px;
}

.settings-block-analytics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  background: var(--grayscale_96);
  border-radius: 12px;

  .add-edit-icon {
    margin-right: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: var(--greyscale_08);
    &.off-status {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--greyscale_40);
    }
    &.plus-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.advanced-activity-zone {
  display: flex;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;

  .activity-field-label {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
  }

  .add-icon {
    cursor: pointer;
  }
}

.analytics-settings-activity-zone {
  .modal-header {
    padding-top: 0px;
  }
  .activity-zone-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--greyscale_56);
    margin-top: 6px;
    &.obj-detection-type {
      color: var(--text_color_obj);
    }
  }

  .hr-devider {
    width: 120px;
    height: 4px;
    border-radius: 16px;
    background: var(--brand_primary);
    margin: 20px 0;
  }

  .main-exculded-container {
    max-height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .area-conatiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .modal-sub-heading-left {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .modal-sub-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .add-line-btn {
    margin-left: auto;
  }

  .add-zone-btn {
    //margin: 0px 8px;
  }
  .margin-top-no-zones {
    margin-top: 30px;
  }
  .button-conatiner-excluded-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    .btn-margin-save {
      margin-left: 5px;
    }
  }
  .button-conatiner-backlight-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-margin-save {
      margin-left: 5px;
    }
  }

  .snap-shot-container {
    position: relative;

    .cam-1x {
      width: 478px;
    }

    .cam-snapshot {
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      aspect-ratio: 16/9;
      object-fit: fill;
      border-radius: 12px;
      width: 478px;

      .cam-snapshot-wrapper {
        height: 100%;
      }
    }

    & .live-snapshot {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      background: var(--greyscale_08);
      aspect-ratio: 16 / 9;

      & .live-snapshot-image {
        z-index: 10;
        // max-width: 100%;
        // width:100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        // aspect-ratio: 16 / 9;
        &.image-aspect {
          aspect-ratio: 1;
        }
      }
    }
  }
}

.regions-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  & .region-btn {
    margin-right: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background-color: #f6f6f6;
    & .circle-dot {
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background-color: var(--brand_black);
    }

    & svg.cross-icon {
      path {
        stroke-width: 1.5px;
        stroke: var(--primary_40);
      }
    }

    &.selected-region {
      background-color: var(--primary_40) !important;
      color: var(--brand_white) !important;

      & svg.cross-icon {
        path {
          stroke-width: 1.5px;
          stroke: var(--brand_white);
        }
      }
    }
  }
}

.line-crossing-count {
  float: right;
  margin-top: 5px;
  font-size: 12px;
  color: var(--primary_03);
}

.regions-button-container {
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 12px;
  background: var(--grayscale_96);
  display: flex;
  flex-wrap: wrap;
}

.add-zone-container {
  .input-box {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background-color: transparent;
    width: 100%;
    position: relative;
    z-index: 10;

    &:disabled {
      background-color: var(--grayscale_96);
    }

    &:focus + .custom-placeholder {
      display: none;
    }

    &.summary-input {
      &::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &::-moz-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &:-ms-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
    }
  }

  & .zone-input-wrapper {
    & .info-msg {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--greyscale_40);
      padding: 4px 20px 0 20px;

      &.error {
        color: var(--error_64);
      }
    }

    &.duration {
      & .info-msg {
        padding: 4px 16px 0 16px;
      }
    }

    & .medium-no-value {
      border-radius: 12px;
      border: 1.5px solid var(--greyscale_88);
      z-index: 10;
      background-color: var(--grayscale_96);
    }
  }

  .direction-container {
    padding: 15px 20px;
    background: var(--grayscale_96);
    border-radius: 12px;
    cursor: pointer;
    &.disable {
      pointer-events: none;
    }
    .direction-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input-label {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        color: var(--hGreyscale_08);
      }
      .input-checkbox {
        height: 20px;
        width: 20px;
        min-width: 20px;
      }
    }
    .direction-row input[type='checkbox'] {
      border: 1.5px solid #cccfd1;
    }
  }

  & .checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--grayscale_96);
    border-radius: 12px;
    &:hover {
      background-color: var(--greyscale_88);
    }
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      text-align: left;
      color: var(--greyscale_08);
    }

    &-input-checkbox {
      height: 20px;
      width: 20px;
      min-width: 20px;

      &:disabled {
        &:checked::after {
          background-color: var(--greyscale_48);
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: var(--greyscale_48);
        border-radius: 3px;
        outline: none;
        border: 1.5px solid var(--greyscale_40);
      }
    }

    & .info-message-container {
      display: flex;

      svg {
        height: 16px;
        width: 16px;
        min-width: 16px;
      }

      & .info-message {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 0em;
        margin-left: 4px;
        color: var(--greyscale_56);
      }
    }
  }
}

.error-conatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
  background: var(--error_96);
  border-radius: 12px;
  color: var(--error_24);

  .icon-warning-col {
    color: var(--error_24);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .error-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
  }
}

.warning-conatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  gap: 8px;
  background: var(--warning_96);
  border-radius: 12px;
  color: var(--warning_24);

  .icon-warning-col {
    color: var(--warning_24);
    height: 20px;
    width: 20px;
    cursor: pointer;

    path {
      color: var(--warning_24);
    }
  }

  .warning-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--warning_24);

    span {
      font-weight: 700;
    }
  }
}

.error-conatiner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
  background: var(--error_96);
  border-radius: 12px;
  color: var(--error_24);

  .icon-error-col {
    color: var(--error_24);
    height: 20px;
    width: 20px;
    cursor: pointer;

    path {
      color: var(--error_24);
    }
  }
  .unsaved {
    flex-basis: 100%;
    font-size: 14px;
    color: var(--error_24);
    margin-left: 30px;
  }
  .error-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
    .error-headline {
      font-weight: 600;
    }
    span {
      font-weight: 700;
    }
  }
}

.input-group-container {
  display: flex;
  gap: 1rem;

  .zone-input-wrapper {
    &.high,
    &.medium {
      width: 56%;
    }
    &.duration {
      width: 28%;
    }
    &.seconds {
      display: flex;
      align-items: center;
      height: 50px;
      width: 14%;
    }
  }
}

.line-container {
  position: absolute;
  top: 0;
  z-index: 12;
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.region-container {
  div[data-dir] {
    border-radius: 4px;
    outline: none !important;
    border: none !important;
  }
  &.region0 {
    div[data-dir] {
      background-color: var(--avatar_dark_blue);
    }
  }
  &.region1 {
    div[data-dir] {
      background-color: var(--avatar_dark_teal);
    }
  }
  &.region2 {
    div[data-dir] {
      background-color: var(--avatar_dark_yellow);
    }
  }
  &.region3 {
    div[data-dir] {
      background-color: var(--avatar_dark_purple);
    }
  }
  &.region4 {
    div[data-dir] {
      background-color: var(--avatar_dark_pink);
    }
  }
  &.region5 {
    div[data-dir] {
      background-color: var(--avatar_dark_orange);
    }
  }
  &.region6 {
    div[data-dir] {
      background-color: var(--avatar_dark_ultramarine);
    }
  }
  &.region7 {
    div[data-dir] {
      background-color: var(--avatar_dark_red);
    }
  }
  &.region8 {
    div[data-dir] {
      background-color: var(--custom_color_1);
    }
  }
  &.region9 {
    div[data-dir] {
      background-color: var(--error_24);
    }
  }
}

.component-container {
  position: relative;
  & .disable-bg {
    background-color: white;
    opacity: 0.5;
    position: absolute;
    z-index: 14;
    height: 100%;
    width: 100%;
  }
}

.object-type-title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text_color);
  font-family: 'Noto Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  &.category {
    margin-bottom: 0px;
  }
}
.object-type-sub-title {
  color: var(--category-color);
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.min-duration-iva {
  background-color: var(--grayscale_96);
  color: var(--custom_color_19);
  font-family: 'Noto Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 20px;
  margin-bottom: -5px;
}

.event-category-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: var(--grayscale_96);
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 3px; // for vertical scroll bar
  }

  .event-type-list {
    margin-top: 10px;
    width: 100%;
    .parent-list-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .left-event-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 8px 15px 5px 10px;
        margin-left: 10px;
        width: 100%;
        &.license-plate {
          margin-top: -10px;
        }

        .icon-title-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .inner-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .title-event {
              color: var(--text_color_event);
              font-family: 'Noto Sans';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              /* 125% */
              margin-left: 10px;
            }
          }
          .img-icon {
            cursor: pointer;
            height: 20px;
            width: 20px;
            &.minus-icon {
              width: 23px;
            }
          }
        }
        .vehicle-margin-layout {
          width: 100%;
          margin-top: 10px;
          .vehicle-list-container {
            margin-left: 10px;
            padding-left: 10px;
            padding-top: 5px;
            padding-bottom: 10px;
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .inner-row {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .title-event {
                color: var(--text_color_event);
                font-family: 'Noto Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 125% */
                margin-left: 10px;
              }
            }
            .img-icon {
              cursor: pointer;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      .right-event-block {
        .input-type {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
@media (min-width: 641px) {
  .analytics-settings-activity-zone .modal-dialog .modal-content {
    min-width: 582px;
  }
}

.sound-cls-types {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 28px;
  color: var(--brand_black);
}
.range-field-wrap-types {
  display: flex;
  padding: 18px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--grayscale_96);
  .type-text {
    color: var(--greyscale_08);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .m-l-r-20 {
    margin-left: 20px;
    margin-right: 20px;
    .toggle-field-wrap {
      padding: 0px;
    }
  }
  .w-90 {
    width: 90%;
    .toggle-field-label {
      color: var(greyscale_08greyscale_08);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.settings-motion-detection-block {
  margin-bottom: 16px;
  align-items: center;
  background: var(--grayscale_96);
  border-radius: 12px;
  .add-edit-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    color: var(--greyscale_08);
    &.off-status {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--greyscale_40);
    }
    &.plus-icon {
      width: 20px;
      height: 20px;
    }
  }

  .row-included-area {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    &.exclude-padding {
      padding-bottom: 15px;
    }

    .title-areas {
      color: var(--text_color);
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 125% */
    }
  }
}

.analytics-text-hint {
  color: var(--greyscale_40);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-right: 20px;
  display: flex;
  font-size: 12px;
  padding-bottom: 20px;
}

.display-f {
  display: flex;
  font-size: 12px;
}
.m-t-1 {
  margin-top: -1px;
}
.m-t-15-m {
  margin-top: -15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-19 {
  margin-left: 19px;
  margin-right: 5px;
}
.text-margin {
  // margin-top: 1px;
  width: 50%;
}

.backlight-settings-activity-zone {
  .modal-title {
    font-weight: 600 !important;
    font-size: toRem(24px) !important;
    line-height: toRem(32px) !important;
  }
}
