@import '../../../../assets/css/base.scss';

.license-block {
  .list-block-item.card {
    background-color: transparent !important;
    border-radius: 0;
    margin-top: 0;
    padding: 0;

    .card-body {
      .MuiAccordion-root {
        background-color: var(--grayscale_96);
        border-radius: 12px;
        box-shadow: none;
        margin-bottom: 8px;
        padding: 0 20px;

        &.product-accordion {
          .product-summary .product-name {
            font-weight: 500;
          }

          .product-summary .num-of-licenses {
            color: var(--success_48);
            font-size: toRem(12px);
          }

          .product-details .product-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .product-list-item-wrapper {
              padding: 10px 0;

              .product-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .product-list-item-name {
                  font-size: toRem(16px);
                  color: var(--greyscale_08);
                }

                .product-list-item-sku {
                  font-size: toRem(10px);
                }

                &-radio-button {
                  &:hover {
                    color: var(--primary_40);
                  }
                }
              }
            }
          }
        }

        &::before {
          background-color: transparent;
        }

        .MuiAccordionSummary-expandIconWrapper {
          margin-right: 2px;
          .MuiSvgIcon-root {
            height: 1.5em;
            width: 1.5em;
          }
        }

        .MuiAccordionSummary-root,
        .MuiAccordionDetails-root {
          padding: 0;
        }
      }
    }
  }
}

.confirm-btn {
  &.disabled {
    color: var(--grayscale_32);
    background-color: var(--greyscale_88);
    outline: 0;
    border: 0;
  }
}

.date-selector-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;

  .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
    outline: 1.5px solid var(--greyscale_88);

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover {
      outline: 1.5px solid var(--primary_40);
      border: 0;

      fieldset {
        border: 0;
      }
    }
  }
}

.warning-container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  margin: 12px 0;
  align-items: center;
  border-radius: 12px;
  background: var(--warning_96);

  .warning-message {
    display: flex;
    color: var(--warning_24);
    font-size: toRem(14px);
    font-weight: 400;
    line-height: 20px;

    svg {
      margin-right: 8px;
      width: 30px;
    }
  }
}

.spinner-center-attach-license {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-area {
  text-align: center;
  padding-bottom: 12px;
  .site-spinner {
    padding: 0px !important;
    border: 1.5px solid var(--storage-clode-text) !important;
  }
}

.spinner-area-label {
  text-align: center;
  font-size: toRem(12px);
  color: var(--storage-clode-text);
  cursor: pointer;
}

.spinner-area-attach-license {
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: -webkit-center;
}

.license-date-picker-style .MuiFormControl-root {
  width: 100% !important;
}

.scrollable-container {
  max-height: 450px;
  overflow-y: auto;
  .card-header {
    height: 12px;
  }
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: var(--brand_white);
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: var(--hGreyscale_72);
  border-radius: 4px;
}

.license-date-area-style {
  padding-left: 4px;
  font-weight: 600;
  font-size: toRem(16px);
  line-height: toRem(20px);
}

.license-remaining-days-label {
  font-weight: 500;
  font-size: toRem(12px);
  line-height: toRem(16px);
  letter-spacing: 0%;
  padding-left: 3px;
}

.daysRemainingCounticon {
  color: var(--warning_48);
  margin-top: -2px;
}

.daysRemainingLabelArea {
  padding-left: 3px;
}

.no-data-style .card-body {
  text-align: center;
  align-content: center;
}
