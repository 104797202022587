@import '../../../../assets/css/_utilities.scss';

.device-infodate-time-title {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  &.step-two {
    margin-bottom: 8px;
  }

  &.duclo-firmware {
    margin-top: 20px;
  }
}

.local-camera-setting {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
}

.http-proxiy-label-title {
  color: var(--text_color_obj);
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
}

.error-container-http {
  border-radius: 12px;
  background: var(--error_96);
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: flex-start;
  margin-top: 16px;

  &.active-seesion-same-channel {
    background-color: var(--warning_96);
  }

  .img-error-info {
    height: 20px;
    width: 2opx;
  }

  .error-message-http {
    margin-left: 8px;
    color: var(--error_24);
    font-family: 'Noto Sans';
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(20px);

    &.active-seesion-same-channel {
      color: var(--warning_24);
    }
  }
}

.remotly-connect-layout {
  margin-top: 16px;
  border-radius: 12px;
  background: var(--grayscale_96);
  display: flex;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left-container-setting {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .remotly-label {
      color: var(--text_color_event);
      font-family: 'Noto Sans';
      font-size: toRem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: toRem(20px);
    }
    .img-launch {
      margin-left: 8px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      &.disable-launch {
        pointer-events: none;
      }
    }
  }

  .right-container-setting {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .connect-disconnect-text {
      color: var(--slider-red);
      font-family: 'Noto Sans';
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 600;
      line-height: toRem(20px);
      cursor: pointer;
      /* 142.857% */
    }
    .connect-disconnect-disabled-text {
      color: var(--primary_05);
      font-family: 'Noto Sans';
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 600;
      line-height: toRem(20px);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.modal-local-ui {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image-icon-div {
    height: 76px;
    width: 76px;
    background-color: var(--error_80);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .img-cross {
      color: var(--error_48);
    }
  }
  .message-modal-connect {
    margin-top: 20px;
    font-family: Noto Sans;
    width: auto;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--text_color_event);
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: block;
    white-space: pre-line;
  }

  .label-modal-text {
    margin-top: 10px;
    color: var(--text_color_obj);
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.settings-info-block-content-step {
  margin-bottom: 5px;
}

.device-info-timezone-dropdown {
  margin-top: 16px;
}

.settings-info-block-heading {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--body_color);
}

.settings-info-block-content {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);

  &.time-zone-select {
    margin-bottom: 10px;
  }

  &.device {
    pointer-events: none;
  }
}

.label-timezone {
  margin-top: -8px;
  margin-left: 18px;
  color: var(--greyscale_56);
  font-size: toRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.device-setting {
    margin-top: 1px;
  }
}

.accordion {
  .accordion-item {
    border: 0;

    .accordion-header {
      .accordion-button {
        background-color: inherit;
        box-shadow: none;

        &:not(.collapsed)::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }

    .accordion-body {
      padding: 8px 16px 5px;

      .radiotitle {
        margin-bottom: 16px;
      }
    }
  }
}

.location-area-container {
  padding: 20px;

  .radiotitle {
    align-items: center;
    padding: 6px 0;
    margin-top: 0.5rem;

    &:first-child {
      margin-top: 0px;
    }

    .subtext2 {
      margin-bottom: 0px;
    }

    .makingBig {
      display: flex;
    }
  }
}

.setting-info-network {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  background: var(--grayscale_96);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  border-style: dashed;
  border-color: var(--greyscale_80);
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--text_color);
  font-weight: 500;
  cursor: pointer;
}

.setting-plus-icon {
  margin: 0 auto 12px;
}

.choose-day {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--brand_black);
}

.week-day {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_40);
}
.range-field-container {
  position: relative;
}
.range-fiel-label-matched {
  color: var(--primary_40);
}
.select-day-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  .day-blocks {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;
    width: 55px;
    background: var(--greyscale_88);
    border-radius: 8px;
    color: var(--greyscale_40);
    font-size: toRem(14px);

    &.selected {
      background: var(--primary_40);
      color: var(--brand_white);
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      height: 45px;
      width: 45px;
    }

    @media (min-width: 400px) and (max-width: 1000px) {
      height: 35px;
      width: 35px;
    }

    .label-value {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
}
.time-container {
  .error {
    border-radius: 12px;
    border: 1.5px solid var(--error_48);
  }
}

.time-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  .timer-start-end {
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;
    font-size: toRem(16px);
    align-items: center;

    .time-label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--text_color);
    }
  }

  .seprator {
    color: var(--greyscale_08);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.settings-info-block-content {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);

  &.time-zone-select {
    margin-bottom: 10px;
  }

  &.device {
    pointer-events: none;
  }
}

.streamingSetting .choose-day {
  margin-bottom: 12px;
}

.streamingSetting .select-day-container {
  padding: 20px;
  background-color: var(--grayscale_96);
  border-radius: 12px;
}

.streamingSetting .select-day-container {
  .day-blocks {
    height: 44px;
    width: 60px;
    font-size: toRem(12px);
  }
}

.settings-block-range {
  margin-top: 25px;
}

.sm-lable {
  font-size: toRem(14px);
  line-height: 20px;
  font-weight: 500;
  color: var(--body_color);
}

.selectd-day {
  margin-top: 8px;
  margin-bottom: 25px;
}

.range-fiel-label {
  font-size: toRem(12px);
  line-height: 16px;
  font-weight: 500;
  color: var(--greyscale_40);
  margin-top: 6px;
}

.label-timezone {
  margin-top: -8px;
  margin-left: 18px;
  color: var(--greyscale_56);
  font-size: toRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.device-setting {
    margin-top: 1px;
  }
}

.streamingSetting .time-container {
  padding: 20px;
  background-color: var(--grayscale_96);
  border-radius: 12px;
}

.streamingSetting .time-container .MuiFormControl-root .MuiInputBase-root {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--brand_white);
  border-radius: 12px;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputBase-root
  input {
  box-sizing: border-box;
  height: 44px;
  padding-left: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--greyscale_88);
}

.streamingSetting .time-container .MuiFormControl-root .MuiInputAdornment-root {
  margin-left: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputAdornment-root
  .MuiButtonBase-root {
  margin-right: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputAdornment-root
  .MuiButtonBase-root
  svg {
  width: 16px;
  height: 16px;
}

.contentSubTitle {
  margin-top: 19px;
}

.device-info-form {
  .tab-title.device-info-title {
    margin-top: 20px;
  }

  .device-infodate-time-title.device-location {
    margin-bottom: 1px;
  }
}

.device-network-settings-block {
  & .editable-off-offline {
    pointer-events: none;
  }
}

.streamingSetting-title {
  .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
  }
}

.max-recording-bitrate-label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: left;
  margin-bottom: 9px;
}

.select-day-container-error {
  border-radius: 12px;
  border: 1.5px solid var(--error_48);
}
