.MuiPickersPopper-root.MuiPopper-root {
  .MuiPaper-root {
    .MuiPickersLayout-contentWrapper {
      display: flex;
      justify-content: space-around;
      height: 318px !important;
    }
  }
}

.MuiPickersPopper-root.MuiPopper-root {
  .MuiPaper-root {
    .MuiPickersLayout-contentWrapper {
      .MuiPickersCalendarHeader-labelContainer {
        min-width: 175px;
      }
      .MuiPickersFadeTransitionGroup-root {
        min-width: 135px;
      }
    }
  }
}
