@import '../../../assets/css/_utilities.scss';

.device-add-btn-icon {
  margin-right: 11px;
}

.settings-block {
  margin-bottom: 16px;

  .info-update {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .info-firmware {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .firmware-update-section {
    margin-top: -12px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .select-field-wrap {
    margin-top: 0;
    margin-bottom: 0;

    .form-select {
      margin-bottom: 0;
    }
  }

  .settings-block-title {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--greyscale_08);
    margin-top: 32px;
  }
}

.alert.alert-danger {
  background-color: var(--error_96);
  border-color: transparent;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;

  .alert-heading {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(16px);
    line-height: 20px;
    color: var(--error_24);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
    margin-bottom: 0;
  }

  .btn-close {
    height: 10px;
    width: 10px;
  }

  .text-alignment {
    margin-left: 1.5%;
  }
}

.btn.btn-primary.attach-license-btn.disabled {
  padding: 10px 16px;
  background-color: var(--grayscale_96);
  outline: 0;
  border: 0;
  color: var(--greyscale_56);
  border: 2px solid var(--greyscale_88);
}

.list-wrapper {
  margin-top: 56px;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
}

.search-container {
  display: flex;
  text-align: center;
  align-items: center;
  width: 424px;
  & .search-icon {
    position: absolute;
    z-index: 9;
    margin: 12px 8px 12px 16px;
  }
  & .search-input {
    display: flex;
    padding: 12px 16px 12px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    color: var(--greyscale-40);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    width: 100%;
    &:hover {
      background-color: var(--brand_white) !important;
      color: var(--greyscale_40) !important;
      border-color: var(--primary_48);
    }
    &:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: var(--brand_primary);
      outline: 0;
    }
  }
}

.device-status-selector {
  &-toggle {
    &.dropdown-toggle {
      display: inline-flex;
      height: 44px;
      padding: 8px 16px;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--greyscale_08);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border: none;
      &.btn-outline-secondary {
        &.show {
          background-color: var(--grayscale_96) !important;
          color: var(--greyscale_08) !important;
          border: none;
        }
      }

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: var(--grayscale_96) !important;
        color: var(--greyscale_08) !important;
        border: none;
      }
    }
  }

  &-menu {
    &.dropdown-menu {
      width: 200px;
      padding: 8px 0px;
      align-items: flex-start;
      margin-top: 8px;
      margin-left: -87px;
      &.show {
        border-radius: 12px;
        background: var(--brand_white);
        box-shadow:
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
          0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);
      }

      & .dropdown-item {
        display: flex;
        padding: 8px 12px !important;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        display: flex;
        &.active {
          color: var(--greyscale_08);
          background-color: var(--brand_white);
          font-weight: 700;
        }

        &:first-child {
          color: var(--brand_black);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        & .checkbox {
          width: 16px;
          height: 16px;
          accent-color: var(--primary_40);
        }
      }
    }
  }

  .device-status-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }
  }
}

.accordian {
  padding-top: 20px;

  & .accordion-item {
    display: flex;
    width: 1110px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: var(--grayscale_96);
    width: 100%;
    margin-bottom: 12px;
    padding: 16px 20px;
    gap: 8px;

    & .image-wrapper {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--brand_white);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      & .device-icon {
        width: 42px;
        height: 28px;
      }
    }
    & .accordion-header {
      width: 100%;
    }
    & .accordion-collapse {
      width: 100%;
    }
    & .accordion-button {
      padding: 0px;
    }
    & .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & .header-section-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      & .header-section-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      & .firmware-update {
        display: flex;
        padding: 2px 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px;
        border: 2px solid rgba(var(--greyscale-custom4), 0.1);
        background: var(--error_96);
        margin-left: 12px;
        & .update-text {
          color: var(--error_24);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
      & .expiring-soon {
        display: flex;
        padding: 2px 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px;
        border: 2px solid rgba(var(--greyscale-custom4), 0.1);
        background: var(--warning_96);
        margin-left: 12px;
        & .update-text {
          color: var(--warning_24);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }
    & .accordion-body {
      padding: 0px;
    }
    & .camera {
      display: flex;
      padding: 0px 32px 0px 8px;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--brand_white);
      box-shadow: 0px 4px 4px 0px rgba(var(--greyscale-custom1), 0.12);
      width: 100%;
      margin-bottom: 16px;
      &.disable-click {
        pointer-events: none !important;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      & .try-again-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--brand_white);
        background-color: var(--grayscale_16);
        border: 1.5px solid var(--greyscale_56);
        font-size: toRem(12px);
      }
      & .camera-details {
        display: flex;
        padding: 16px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        overflow-x: auto;

        & .camera-name {
          color: var(--greyscale_08);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
          & .firmware-update {
            display: flex;
            padding: 2px 12px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 8px;
            border: 2px solid rgba(var(--greyscale-custom4), 0.1);
            background: var(--error_96);
            margin-left: 12px;
            & .update-text {
              color: var(--error_24);
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
            }
          }
          & .expiring-soon {
            display: flex;
            padding: 2px 12px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 8px;
            border: 2px solid rgba(var(--greyscale-custom4), 0.1);
            background: var(--warning_96);
            margin-left: 12px;
            & .update-text {
              color: var(--warning_24);
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
            }
          }
        }
        & .detail-table {
          display: flex;
          align-items: flex-start;
          gap: 48px;
          align-self: stretch;
          & .table-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
          }
          & .table-header {
            color: var(--greyscale_40);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
            display: flex;
          }
          & .license {
            width: 241px;
          }
          & .attach-license {
            color: var(--primary_40);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            text-align: start;
          }
          & .table-info {
            color: var(--greyscale_08);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: start;
          }
          & .width-100 {
            width: 100px;
            max-width: 100px;
          }
          & .width-120 {
            width: 120px;
            max-width: 120px;
          }
          & .width-150 {
            width: 150px;
            max-width: 150px;
          }
          & .width-180 {
            width: 180px;
            max-width: 180px;
          }
          & .width-200 {
            width: 200px;
            max-width: 200px;
          }
          & .feature-check-circle {
            color: var(--success_24);
            background-color: var(--brand_white);
            font-size: toRem(18px);
            margin-left: 8px;
          }
          & .status-indicator {
            margin-right: 0.375rem;
            height: 0.625rem !important;
            width: 0.625rem !important;

            &.status {
              &-online {
                color: var(--success_48);
              }

              &-claiming {
                color: var(--primary_48);
              }

              &-entered {
                color: var(--warning_64);
              }

              &-offline {
                color: var(--error_112);
              }

              &-ready-to-claim {
                color: var(--greyscale_40);
              }
            }
          }
        }
      }
    }
  }
}

.no-devices-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: var(--greyscale_08);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-top: 146px;
  & .image-wrapper {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--grayscale_96);
  }
}

.error-container {
  display: flex;
  padding: 0.75rem;
  background: var(--error_96);
  border-radius: 0.75rem;
  margin: 1.5rem 0;

  .info-icon-container {
    display: flex;

    svg path {
      stroke: var(--error_24);
    }
  }

  .error-body {
    display: flex;
    justify-content: space-between;
    line-height: 1.25rem;
    color: var(--error_24);
    width: 100%;

    .error-content {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0 0.5rem;
    }

    .cross-icon-container {
      display: flex;
      cursor: pointer;

      svg path {
        stroke: var(--error_24);
      }
    }
  }
}

.setting-icon {
  cursor: pointer;
  &.disable-click {
    pointer-events: none !important;
  }
}

.disable-icon {
  cursor: not-allowed;
}

.channel-details.device-tabs {
  margin-bottom: 36px;

  .section-tabs {
    justify-content: start !important;

    .section-tab {
      display: flex;
    }
  }
}

.channel-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 296px;
  background: var(--grayscale_96);
  border-radius: 12px;
  padding: 24px 0;
  .device-icon {
    height: inherit;
  }
}

.section-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--brand_black);
}

.device-info-section {
  background: var(--grayscale_96);
  padding: 20px;
  border-radius: 12px;
}

.device-info-section-local-storage {
  background: var(--grayscale_96);
  padding: 20px;
  border-radius: 12px;

  .recording-day-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .heading-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0%;
        vertical-align: middle;
        color: var(--category-color);
      }

      .days-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0%;
        vertical-align: middle;
        color: var(--greyscale_08);

      }
    }

    .right-container {
      font-weight: 500;
      font-size: 16px;
      margin-top: 20px;
      line-height: 20px;
      letter-spacing: 0%;
      vertical-align: bottom;
      color: var(--storage-clode-text);
      cursor: pointer;

    }
  }
}

.license-expired-area-style {
  display: flex;
  padding: 2px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid rgba(var(--greyscale-custom4), 0.1);
  background: var(--error_96);
  margin-left: 12px;
  & .license-expired-text-style {
    color: var(--error_24) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
}

.license-expiring-soon-area-style {
  display: flex;
  padding: 2px 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid rgba(var(--greyscale-custom4), 0.1);
  background: var(--warning_96);
  margin-left: 12px;
  & .license-expiring-soon-text-style {
    color: var(--warning_24);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
.cloud-storage-recording-profile {
  .cloud-storage-text-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .m-t-16 {
    margin-top: 16px;
  }

  .cloude-storage-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: var(--storage-clode-text);
    cursor: pointer;
  }
  .inside-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 12px 0px;
  }
  .space-btn-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .codec-selection > input {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }

  .codec-selection input[type='radio'] {
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    border: 2px solid var(--greyscale_80);
    background: var(--brand_white);
  }

  .codec-selection input[type='radio']:before {
    content: '';
    display: block;
    width: 64%;
    height: 64%;
    margin: 18% auto;
    border-radius: 50%;
  }

  .codec-selection input[type='radio']:checked:before {
    background: var(--primary_40);
  }

  .codec-selection input[type='radio']:checked {
    border-color: var(--primary_40);
  }

  .codec-selection-offline {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.selection-disable {
      pointer-events: none;
    }

    .label-codec {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }
  }
  .codec-selection-offline > input {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }

  .codec-selection-offline input[type='radio'] {
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    border: 2px solid var(--greyscale_80);
    background: #797a7b;
  }

  .codec-selection-offline input[type='radio']:before {
    content: '';
    display: block;
    width: 64%;
    height: 64%;
    margin: 18% auto;
    border-radius: 50%;
  }

  .codec-selection-offline input[type='radio']:checked:before {
    background: var(--custom_color_disabled);
  }

  .codec-selection-offline input[type='radio']:checked {
    border: 1.5px solid #444648;
  }
}
.storage-enable-button {
  .p-0 {
    padding: 0px;
  }
  .m-t-0 {
    margin-top: 0px;
  }
}

.parent-layour-retention{
  height: auto;
  .retention-days-field-layout {
    margin-top: 15px;
    width: 100%;
    border-radius: 12px;
    padding: 5px;
    border: 1.5px solid var(--hGreyscale_84);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.error-layout{
      border: 1.5px solid var(--error_64);
      background: var(--error_96);
    }
  
    .left-container-input-field{
      .no-background {
        background: none; /* Removes the background */
        border: none ; /* Add border if needed */
        padding: 8px;
        font-size: 16px;
        outline: none;
      }
    }
      .days-label {
        margin-right: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0%;
        color: var(--greyscale_48);
      }
    
  
  }
  .error-message-layout{
    margin-top: 2px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .info-icon{
      color: var(--greyscale_40);
    }
    .error-label{
      margin-left: 5px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0%;
      color: var(--greyscale_40);
    }
  }
  .btn-rentention{
    margin-top: 20px;
  }
}

