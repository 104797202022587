@import '../../assets/css/_utilities.scss';

.tools-list-container {
  & .row,
  .col {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}

.list-block {
  height: calc(100vh - 236px);

  & .table tbody td {
    color: var(--hGreyscale_08);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  & .table thead td,
  & .table thead th {
    color: var(--hGreyscale_56);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  & .loader-container {
    left: 47%;
  }

  &.no-bandwidth-available {
    background-color: var(--grayscale_96);
    border-radius: 12px;
    margin: 0 12px;
    height: calc(100vh - 174px);
  }

  & .no-tools-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Noto Sans';
    font-size: toRem(14px);
    font-style: normal;
    line-height: toRem(20px);
    color: var(--custom_color_19);

    .search-icon-wrapper {
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .image-wrapper {
      background: transparent;
    }

    & .bandwidth-no-content-title {
      text-align: center;
      font-size: toRem(18px);
      font-weight: 600;
      line-height: toRem(24px);
    }

    & .bandwidth-no-content {
      color: var(--primary_03);
      font-weight: 500;
    }

    & .bandwidth-new-estimate {
      color: var(--primary_40);
      font-weight: 600;
      padding: 12px;
      margin-top: 16px;
      cursor: pointer;
    }
  }
}

.bandwidth-popup {
  & .bw-estimation-button-group {
    margin: 0px;
    width: 100%;
  }

  .bw-name-container {
    position: relative;

    & .bw-estimation-name-input {
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 12px;
      border: 1.5px solid var(--greyscale_88);
      background-color: transparent;
      height: 44px;
      width: 100%;
      position: relative;
      z-index: 10;

      &.has-value {
        &:focus + .custom-placeholder {
          display: none;
        }
      }

      &:active,
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        border-color: var(--primary_40);
        outline: 0;
      }

      &.input-error {
        color: var(--error_48);
        font-size: toRem(16px);
        font-style: normal;
        font-weight: 500;
        line-height: toRem(20px);
        border: 2px solid var(--error_80);
      }
    }

    & .helper-text-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .helper-text {
        color: var(--error_64);
        font-family: 'Noto Sans';
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);
        display: flex;
        flex-direction: row;

        & svg {
          height: 16px;
          width: 16px;
        }

        & .error {
          margin-top: 0px;
          margin-left: 4px;
        }
      }
      & .text-count {
        color: var(--hGreyscale_48);
        text-align: right;
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);

        &.error {
          color: var(--error_64);
          margin-top: 0px;
        }
      }
    }
  }

  .modal-footer {
    padding: 0px;
  }
}

.delete-save-btn {
  .site-spinner {
    background: var(--primary_40);
  }
}

.search-wrapper-container {
  display: flex;
  justify-content: space-between;

  & .date-range-filter {
    display: flex;
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1.5px solid var(--hGreyscale_88);
    background: var(--brand_white);
    width: 280px;
    cursor: pointer;

    &.active {
      border: 1.5px solid var(--primary_40);
    }

    & .date-range-text {
      color: var(--text_color);
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 500;
      line-height: toRem(20px);
    }

    & svg {
      height: 20px;
      width: 20px;
    }
  }

  & .date-range-filter-container {
    position: relative;

    & .date-picker-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      background: var(--brand_white);
      border-radius: 16px;
      width: 342px;
      right: 0px;
      top: 48px;
      position: absolute;
      z-index: 99999;
      border-radius: 16px;
      box-shadow:
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    }
  }
}

#menu-tools-list-menu {
  .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPopover-paper:has(
      .context-menu-list
    ):has(.MuiButtonBase-root.MuiMenuItem-root) {
    &:has(.tools-list-menu-item-option) {
      margin-top: -35px;
      margin-left: -196px;
    }

    &:has(.comment-item-text) {
      margin-top: -36px;
      margin-left: -100px;
    }

    .menu-item-expandable-list-item {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0;

      &:first-child {
        padding-top: 4px;
      }

      &-icon {
        min-width: 26px;
      }

      &-text span {
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: toRem(20px);
      }
    }
  }

  &.context-menu {
    .MuiPaper-root {
      .context-menu-list {
        li {
          padding: 8px 16px;

          &:first-child {
            padding-top: 12px;
          }

          &:last-child {
            padding-bottom: 12px;
          }

          .menu-item {
            &-icon {
              height: toRem(20px);
              width: toRem(20px);
              min-width: toRem(20px);
              margin-right: 8px;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }

            &-text span {
              color: var(--custom_color_19);
              font-size: toRem(14px);
              font-weight: 500;
              line-height: toRem(20px);
              overflow-wrap: break-word;
              text-wrap: wrap;

              &.notification-text {
                color: var(--brand_black);
                font-size: toRem(15px);
              }
            }

            &-expandable {
              display: flex;
              flex-direction: column;
              width: 100%;

              &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;

                .MuiListItemText-root span {
                  color: var(--custom_color_19);
                }
              }
            }
          }
        }
      }
    }
  }
}
