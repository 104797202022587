@import '../../assets/css/base.scss';

body {
  // This hides the scrollbars on the body tag without
  // impacting scrolling in Chrome, Safari, Edge, Opera
  // and other webkit-based web browsers.
  &::-webkit-scrollbar {
    display: none;
  }
}

.clips-list {
  &-container {
    background-color: var(--greyscale_08);
    margin-top: 0;
    min-height: 100vh;
  }

  .clips-storage-wrapper {
    margin-left: 0;
    margin-bottom: 18px;
    min-height: 24px;

    .clips-total {
      color: var(--hGreyscale_72);
      font-size: toRem(16px);
      font-weight: 400;
      line-height: toRem(24px);
    }
  }

  &-title {
    color: var(--brand_white);
  }

  .clips-main-container {
    max-width: 1440px;

    .page-header {
      .page-title {
        padding-left: 0;
      }

      .toast-wrapper {
        .clips-list-toast {
          width: 450px;

          .toast-body {
            background-color: var(--brand_white);
          }
        }
      }
    }
  }

  .clips-total {
    color: var(--hGreyscale_72);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-top-cta-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .manage-tags {
      &-button {
        display: flex;
        width: 125px;
        height: 44px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1.5px solid var(--primary_40);
        background-color: var(--greyscale_08);

        &:active {
          border: 0 !important;
          background-color: var(--primary_24) !important;
          outline: 0;
        }

        &:hover {
          color: var(--brand_white);
          border: 1.5px solid var(--primary_40);
          background-color: var(--primary_40);
          box-shadow: 0px 4px 12px 0px rgba(36, 59, 83, 0.2);
          outline: 0;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          border: 2px solid var(--primary_24);
          background-color: var(--primary_40);
          outline: 0;
        }

        &-label {
          color: var(--brand_white, var(--dark_brand));
          text-align: center;
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
        }
      }
    }

    .clips-filter {
      padding: 0;
      margin: 0;
      border-radius: 10px;
      background: var(--brand_white);
      width: 300px;

      &.MuiAutocomplete-root.Mui-expanded.Mui-focused {
      }

      .MuiInputBase-root.MuiOutlinedInput-root {
        background-color: var(--grayscale_24);
        border-radius: 10px;
        height: 44px;
        padding: 0;

        &:hover {
          color: var(--brand_white);
          outline: 1px solid var(--brand_white);
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          outline: 1px solid var(--brand_white);
        }
      }

      input {
        width: 300px;
        height: 20px;
        display: flex;
        padding: 12px 16px 12px 6px !important;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        color: var(--brand_white);

        &::placeholder {
          color: var(--brand_white);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
          opacity: 1;
        }
      }

      .clip-search-icon {
        color: var(--brand_white);
        margin-left: 16px;
      }

      .clip-down-icon {
        color: var(--greyscale_40);
        margin-right: 16px;
      }

      fieldset {
        border-color: var(--grayscale_24) !important;
        border-radius: 10px;
      }
    }

    .users-list-wrapper-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .users-list-wrapper {
      min-width: 200px;
      height: 44px;

      .clips-list-form-control {
        background-color: var(--grayscale_24);
        border-radius: 10px;
        height: 44px;
        min-width: 200px;
        outline: 0;

        &:active,
        &:hover {
          outline: 1px solid var(--brand_white);
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          outline: 1px solid var(--brand_white);
        }

        .MuiFormLabel-root.MuiInputLabel-root.users-select-label {
          color: var(--brand_white);
          top: -4px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            color: var(--brand_white);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
          }
        }

        .MuiOutlinedInput-root.users-select {
          background-color: var(--grayscale_24);
          border-radius: 12px;
          min-width: 200px;

          .MuiSelect-select.MuiSelect-outlined {
            padding: 0;
            height: 44px;
          }

          .Mui-select {
            height: 44px;
          }

          .selected-user {
            color: var(--brand_white);
            display: block;
            text-align: left;
            align-items: center;
            margin-top: 13px;
            margin-left: 14px;
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .MuiSelect-icon {
            display: none;
          }

          fieldset {
            border: 1.5px solid var(--grayscale_24);
            border-radius: 10px;
            outline: 0;
          }
        }
      }
    }
  }

  .clips-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;

    &.justify-left {
      justify-content: flex-start;
      align-items: flex-start;
      height: calc(100vh - 284px);
    }

    .clip {
      &-card {
        height: 262px;
        width: 312px;
        cursor: pointer;
        margin-bottom: 5px;
      }

      &-snapshot {
        height: 175px;
        position: relative;

        &-image {
          border-radius: 12px 12px 0px 0px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px 12px 0px 0px;
          }
        }

        &-image-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          aspect-ratio: 16/9;
          height: 175px;
          width: 312px;
          overflow: hidden;
          background: var(--brand_black);

          span {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &:hover .clip-snapshot-menu,
        &:active .clip-snapshot-menu,
        &:focus .clip-snapshot-menu {
          opacity: 1;
          animation: fadeIn 0.15s ease;
        }

        &-menu {
          position: absolute;
          padding: 12px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          background-color: var(--brand_white);
          top: 0px;
          left: 0px;
          z-index: 20;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 100%;
          width: 100%;
          opacity: 0;
          background: rgba(51, 51, 51, 0.6);
          backdrop-filter: blur(2px);

          &.active {
            opacity: 1;
          }

          &-dots {
            color: var(--primary_40);
          }

          &-contextual {
            &-container {
              position: absolute;
              top: 12px;
              right: 12px;
              border-radius: 8px;
              background-color: var(--brand_white);
            }

            &-menu {
              margin-top: -50px;
              height: 24px;
              width: 24px;
            }
          }
        }

        .clip-video-processing {
          background-color: var(--greyscale-48);
          position: absolute;
          border-radius: 12px 12px 0px 0px;
          height: 175px;
          width: 312px;
          display: flex;
          justify-content: center;
          align-items: center;

          &-alert-wrapper {
            background-color: var(--greyscale-48);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            gap: 12px;
            flex: 1 0 0;
            align-self: stretch;

            .clip-video-processing-snapshot-alert {
              position: absolute;
              z-index: 20;
              top: 25%;
            }
          }
          .spinner {
            position: absolute;
            z-index: 20;
            top: 40%;
            left: calc(50% + 12px);
          }

          &-snapshot {
            border-radius: 12px;
            position: absolute;

            img {
              width: 100%;
              aspect-ratio: 16/9;
              border-radius: 12px 12px 0px 0px;
            }
          }

          &-status {
            position: absolute;
            z-index: 20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 175px;
            width: 100%;
            margin-top: 0px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            border-radius: 12px 12px 0px 0px;
            background: rgba(0, 16, 41, 0.6);

            &-title {
              color: var(--brand_white);
              text-align: center;
              font-size: toRem(14px);
              font-weight: 400;
              line-height: toRem(20px);
              text-align: center;
              position: absolute;
              z-index: 20;
            }

            &-message {
              color: var(--brand_white);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: normal;
              text-align: center;
              width: 292px;
              margin-top: 12px;
              text-decoration: underline;
              position: absolute;
              z-index: 20;
            }
          }
        }
      }

      &-comment-details {
        border-radius: 0px 0px 12px 12px;
        background-color: var(--grayscale_24);
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: start;
        height: 86px;
        gap: 12px;

        .clip {
          &-comment {
            text-align: left;
            overflow: hidden;
            color: var(--hGreyscale_80);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: toRem(12px);
            font-weight: 400;
            line-height: normal;
            width: 290px;
          }

          &-subscribers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 10px;
          }

          &-creator-wrapper {
            text-align: left;
            color: var(--greyscale_88);

            .clip-creator-name {
              color: var(--hGreyscale_88);
              font-size: toRem(12px);
              font-weight: 600;
              line-height: normal;
              white-space: nowrap;
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 170px;
            }

            .clip-create-date {
              text-align: left;
              color: var(--hGreyscale_72);
              font-size: toRem(12px);
              font-weight: 400;
              line-height: toRem(16px);
              white-space: nowrap;
            }
          }

          &-shared-wrapper {
            width: 100%;
          }
        }
      }
    }

    .no-clips {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .no-matching-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 474px;
        justify-content: space-between;
        margin-top: 12%;

        .no-match {
          &-title {
            color: var(--brand_white);
            text-align: center;
            font-size: toRem(18px);
            font-weight: 600;
            line-height: toRem(24px);
            margin-top: 16px;
          }

          &-message {
            color: var(--greyscale_64);
            text-align: center;
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
            margin-top: 8px;
          }
        }

        .clips-link {
          cursor: pointer;
          color: var(--primary_40);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
          text-decoration-line: underline;
        }
      }
    }
  }

  .no-clips {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: calc(100vh - 132px);
      width: 100%;
    }

    &-content {
      height: 100%;
      margin-top: 18%;
    }

    &-headline {
      color: var(--greyscale_08);
      text-align: center;
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: toRem(24px);
    }

    &-description {
      color: var(--greyscale_40);
      text-align: center;
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
    }
  }

  .spinner-container {
    display: 'flex';
    justify-content: 'space-around';
    align-items: 'center';
    height: 100px;
    position: relative;

    .loading-text {
      color: var(--brand_white);
    }
  }
}

.MuiList-root {
  .users-item-wrapper {
    color: var(--brand_black);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
    display: flex;
    justify-content: flex-start;

    .users-item-radio {
      padding-right: 0;
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.6);
      &.Mui-checked {
        color: var(--primary_40);
      }
      &:hover {
        background-color: transparent;
      }
    }

    .users-item-checkbox {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0px;
      margin-right: 8px;
      color: var(--primary_24);

      &.Mui-checked {
        color: var(--primary_40);
      }
      &:hover {
        background-color: transparent;
      }
    }

    &.Mui-selected {
      background-color: var(--primary_88);
      &:hover {
        background-color: var(--primary_88);
      }
    }
    &:hover {
      background-color: var(--primary_96);
    }
  }
}

#menu-clip-menu {
  color: var(--brand_white);

  .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPopover-paper:has(
      .context-menu-list
    ):has(.MuiButtonBase-root.MuiMenuItem-root) {
    &:has(.menu-item-text.clip-menu-item-option) {
      background-color: var(--brand_white) !important;
      margin-left: -4px;
      height: 160px !important;
      width: 160px !important;
      overflow-y: hidden;
    }
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper.css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper {
  color: var(--brand_white);
  box-shadow:
    0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
    0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
  border-radius: 12px;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper {
  .MuiAutocomplete-noOptions {
    color: var(--brand_white) !important;
  }
}

.css-1si533m:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--brand_white) !important;
}

.clips-add-to-incident-modal {
  .modal-dialog {
    overflow-y: initial !important;

    .modal-content {
      .modal-header {
        padding-top: 0px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        justify-content: space-between;
      }

      .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: auto !important;
        scrollbar-color: var(--greyscale-48) !important;

        .add-to-incident {
          &-confirmation {
            &-description {
              color: var(--greyscale_56);
              font-size: toRem(16px);
              font-weight: 400;
              line-height: toRem(24px);
            }

            &-success-icon {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin: 20px auto 5px;
            }
          }

          &-error {
            padding: 20px;
            border: 1px solid var(--error_24);
            border-radius: 12px;
            margin-bottom: 20px;
            background-color: var(--error_96);

            &-message {
              color: var(--error_48);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: toRem(28px);
            }
          }

          &-radio {
            color: var(--greyscale_80);
            margin-right: -5px;
            padding-right: 0;

            &.Mui-checked {
              color: var(--primary_40);
            }

            &-group {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            &-label,
            &-row {
              color: var(--greyscale_08) !important;
              display: flex;
              width: 100%;
              padding: 12px 16px;
              flex-direction: row;
              align-items: flex-start;
              gap: 12px;

              border-radius: 12px;
              background-color: var(--grayscale_96);
              margin-bottom: 8px;

              font-size: toRem(16px) !important;
              font-weight: 500;
              line-height: toRem(20px) !important;

              .add-to-incident-summary {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .add-to-incident-folder {
                  color: var(--greyscale_08);
                  height: 20px;
                  width: 20px;
                  margin-right: 8px;
                }

                .add-to-incident-summary-title {
                  color: var(--greyscale_08);
                  font-size: toRem(16px);
                  font-weight: 500;
                  line-height: toRem(20px);
                  white-space: initial;
                  width: 376px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }

      .modal-footer {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        border: 0;

        .add-to-incident,
        .add-to-incident-confirmation,
        .create-clip {
          &-button-group {
            width: 100%;
          }

          &-button-wrapper {
            width: 100%;
            margin-top: 8px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0px;
          }

          &-save-btn,
          &-go-to-incident-btn,
          &-watch-clip-btn {
            font-size: toRem(18px) !important;
            line-height: toRem(24px) !important;
            font-weight: 600 !important;
            height: 56px;

            &:hover {
              background-color: var(--primary_24);
            }

            &.disabled {
              color: var(--grayscale_32);
            }
          }

          &-create-new-btn.btn.btn-outline-secondary,
          &-back-to-video-btn.btn.btn-outline-secondary,
          &-close-btn.btn.btn-outline-secondary {
            font-size: toRem(18px);
            line-height: toRem(24px);
            font-weight: 600;
            width: 100%;
            height: 44px;
            color: var(--primary_40);
            border-color: var(--primary_40);

            &:focus {
              border-color: var(--primary_40);
              background-color: var(--brand_white);
            }

            &:hover {
              background-color: var(--primary_96);
            }
          }
        }

        .new-incident-button-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper.MuiPopover-paper {
  &:has(.MuiList-root.MuiList-padding.MuiMenu-list) {
    &:has(.users-item-wrapper) {
      background-color: var(--grayscale_24) !important;

      &::state(webkit-scrollbar) {
        display: none;
      }
    }

    .MuiMenu-list {
      width: 100%;
    }

    .users-item-wrapper {
      color: var(--brand_white);
      padding-left: 10px;
      padding-right: 10px;

      &.Mui-focused,
      &.Mui-selected,
      &[aria-selected='true'] {
        background-color: var(--grayscale_24) !important;
      }

      &:hover {
        background-color: var(--hGreyscale_40) !important;
      }
    }
  }
}

.active-lic-modal {
  .title {
    position: relative;
    color: var(--greyscale_08);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .subtitle {
    color: var(--greyscale_56);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .close {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  table {
    // border-collapse: collapse;
    width: 100%;
    background-color: transparent;
    margin-top: 24px;
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  td {
    border: none;
    padding: 10px 16px;
    text-align: center;
    background: transparent;
  }

  th {
    background-color: transparent;
    overflow: hidden;
    color: var(--greyscale_40);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Noto Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  tr {
    margin: 10px 0; /* Margin between rows */
  }
  .row {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 12px;
  }
  /* Alternate row colors */
  .row:nth-child(even) {
    // background-color:var(--grayscale_96);
  }
  .tr-bg {
    background: var(--grayscale_96);
    td {
      overflow: hidden;
      color: var(--greyscale_08);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.active-lic-success-error {
  .pop-holder {
    .title {
      position: relative;
      color: var(--greyscale_08);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      display: flex;
      justify-content: center;
      div {
        width: 60%;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
  .img-holder {
    display: flex;
    justify-content: center;
    div {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  .subtitle-error {
    color: var(--greyscale_56);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 70%;
  }
  .is-center {
    display: flex;
    justify-content: center;
  }
  .m-t-8 {
    margin-top: 8px;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
}
