.manage-licenses {
  .page {
    &-title {
      padding-left: 0;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &-header {
      .licenses-button-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .order-history-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          border: 1.5px solid var(--primary_40);
          background-color: var(--brand_white);
          padding: 12px 16px;
          height: 44px;
          margin-right: 8px;

          &:hover {
            border-radius: 10px;
            border: 1.5px solid var(--primary_40);
            background-color: var(--primary_96);
          }

          &:focus,
          &:active {
            border-radius: 10px;
            border: 2px solid var(--primary_24);
          }

          &-label {
            color: var(--primary_40);
            font-size: toRem(14px) !important;
            font-weight: 600;
            line-height: toRem(20px) !important;
          }
        }

        .purchase-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          padding: 8px 16px;
          background-color: var(--primary_40);
          height: 44px;

          &:hover {
            border-radius: 10px;
            background: var(--primary_24);
            box-shadow: 0px 4px 12px 0px rgba(36, 59, 83, 0.2);
          }

          &:focus,
          &:active {
            border-radius: 10px;
            border: 2px solid var(--primary_24);
            background: var(--primary_40);
          }

          &-label {
            font-size: toRem(14px) !important;
            font-weight: 600;
            line-height: toRem(20px) !important;
          }
        }
      }

      .toast-wrapper {
        text-align: left;
      }
    }
  }

  .licenses-table {
    thead {
      border-spacing: 0;

      th {
        border: 0;
        color: var(--greyscale_56);
        max-width: 120px;

        .header-sort {
          cursor: pointer;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .column-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
          }

          .sort-icon {
            margin-right: 20px;
            font-size: 1.5rem;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: var(--greyscale_88);
        }
        td {
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-left: 0;
          border-right: 0;

          .date-field {
            display: inline-flex;
            align-items: center;

            .calendar-icon {
              margin-right: 0.406rem;
            }

            .exclamation-icon {
              margin-right: 0.406rem;
              color: var(--warning_48);
            }

            .warning-date {
              color: var(--warning_48);
            }
          }

          .status {
            &-field {
              display: inline-flex;
              align-items: center;
            }

            &-indicator {
              margin-right: 0.375rem;
              height: 0.625rem;
              width: 0.625rem;
            }

            &-active,
            &-available {
              color: var(--success_48);
            }

            &-inactive {
              color: var(--greyscale_40);
            }

            &-expiring-soon {
              color: var(--warning_64);
            }

            &-expired {
              color: var(--error_48);
            }
          }
        }
      }
    }
  }
}

.enterTextManullyStyle {
  pointer-events: none !important;
  padding-top: 2%;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--greyscale_56);
  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.enterTextManullyHighlightedStyle {
  cursor: pointer !important;
  padding-top: 4%;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--primary_40);

  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.didNotGetCodeTextStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  padding-left: 3px;
  margin-top: -12px;
}

.clickToResendTextStyle {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  text-decoration: underline;
  padding-left: 6px;
  margin-top: -12px;
}

.clickToResendTextStyle1 {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  padding-left: 6px;
  margin-top: -12px;
}

.large-text-box {
  height: 64px !important;
  width: 64px !important;
  text-align: center !important;
  font-family: Noto Sans !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}

.license-name {
  .col-md-2 {
    width: 14% !important;
  }
}

.cofirmationCodeMessageStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.confirmStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.LicenseSuccessRoundStyle {
  margin-left: -15px;
}

.LicenseSuccessMarkStyle {
  margin-left: -64px;
}

.LicenseSuccessMessageStyle {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 21px;
  padding-left: 79px;
  padding-right: 76px;
}

.LicenseTextFieldStyle {
  margin-top: 12px;
}

.licenseRemoveIconStyle {
  margin-top: 12px;
}

.removableLicenseTextFieldStyle {
  padding-right: 0% !important;
}

.mark-icon-class {
  fill: none !important;
  stroke: none !important;
}

.erroMessage {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
}

.erroMessageIconStyle {
  margin-left: 21px;
}

.marginBottomKey {
  margin-top: -14px !important;
}

.title-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--brand_white);
  margin-top: 9px;
}

.site-modal-close {
  cursor: pointer !important;
}

.errorStyle1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--error_64);
  padding-left: 3px;
  margin-top: -15px;
  padding-bottom: 12px;
}

.addLicenseBtnStyle {
  outline: none !important;
  font-weight: 600 !important;
  width: 136px;
}

.plusIconClass {
  margin-right: 11px;
  margin-left: 6px;
}

.load-more-style {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  color: var(--storage-clode-text);
  cursor: pointer;
}

.loader-license-padding {
  padding-left: 5px;
}

.available-license-status {
  font-weight: 500;
  font-size: toRem(14px);
  line-height: toRem(20px);
  letter-spacing: 0%;
  color: var(--success_48);
  margin-left: 2px;
  margin-top: -1px;
}

.active-license-status {
  font-weight: 500;
  font-size: toRem(14px);
  line-height: toRem(20px);
  letter-spacing: 0%;
  color: var(--active-license-status);
  margin-left: 2px;
  margin-top: -1px;
}

.expired-license-status {
  font-weight: 500;
  font-size: toRem(14px);
  line-height: toRem(20px);
  letter-spacing: 0%;
  color: var(--error_48);
  margin-left: 2px;
  margin-top: -1px;
}

.expiring-soon-license-status {
  font-weight: 500;
  font-size: toRem(14px);
  line-height: toRem(20px);
  letter-spacing: 0%;
  color: var(--warning_48);
  margin-left: 2px;
  margin-top: -1px;
}

.license-filter {
  padding-right: 12px;
}

.license-row-value-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.license-filter-label {
  cursor: pointer !important;
}

#headerlicenseDaysRemaining .column-name {
  width: 108px;
}

.filter-option-color {
  color: var(--primary_16) !important;
}
